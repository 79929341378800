import React from 'react';

import Layout from '../components/layout';
import Logo from '../components/logo';
import Menu from '../components/menu';
import { InnerSection } from '../components/section';
import Slider from '../components/slider';
import Footer from '../components/footer';
import { ModalContext } from '../components/context/Modal';

export default function IndexPage(props) {
  return (
    <Layout>
      <div className="header sub-page">
        <Menu {...props} />
        <div className="hero-section sub-page">
          <span className="tagline">What We Do</span>
          <span className="sub-header">
            We currently manage and/or own a variety of not only Asset Classes
            but also of Asset Quality throughout the Greater Columbus, Ohio
            Metropolitan Area.
          </span>
        </div>
      </div>
      <div className="body-container sub-page what-we-do">
        <div className="row">
          <InnerSection
            title="Property Management"
            body="Whether it is a Ground-up New Construction or the Turn Around of an Existing Asset The Scioto Management Group is dedicated to the value-added approach to real estate investment.  We thoughtfully approach the positioning of the asset to attract the target market that will best realize the property’s potential.  The next Challenge is to maintain the vigilance in keeping the asset competitive in its market and to sustain the highest possible return over the long term."
            // linkText="See our properties"
          />
          <InnerSection
            title="Due Diligence"
            body="Our Approach to Due-Diligence is multifaceted but simple.  Initally we review all available documentation and place key elements into a preliminary model.  If the preliminary model using the sellers real data meets our initial criteria we move forward.  The next evaluation is to take the preliminary results and add in an potential value add enhancements from both a cost and rental increase perspsetive.  Once again this data is placed into the next level of modeling.  If this modeling continues to meet or exceed the investment criteria we move forward to puruse the acquisiton."
            // linkText="Learn More"
          />
        </div>
        <div className="row">
          <InnerSection
            title="Market Analysis"
            body="Our search for properties is driven by market research. So is our Company. We are business people who couple grass roots research with narrowing rings of market influence with a sensitivity to macro-economics to see the potential acquisitions as they really are.  Because, events around the country can affect profit potential just as much as events across the street."
            // linkText="See our properties"
          />
          <InnerSection
            title="Brokerage Representation"
            body="Our approach it simple and straightforward - Listen to You, our Client.  Then ask questions to make sure we thoroughly understand your goals and obkjectives.  From here we move forward with a seasoned knowledge of the market and it’s driving forces we spend time researching to ensure that our assumptions are current, relative and will produce the desired outcome for you.  "
            // linkText="Learn More"
          />
        </div>
        <div className="row">
          <div className="contact-section">
            <span className="text">Interested in learning more?</span>
            <ModalContext.Consumer>
              {({ isModalOpen, setModalState }) => (
                <a onClick={() => setModalState(true)}>
                  <button className="contact-button">Contact Us</button>
                </a>
              )}
            </ModalContext.Consumer>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
